import { userRoles } from 'app/constants/userRoles';

export const formatUserFullName = user => {
  if (!user) {
    return '';
  }
  return `${user.firstName ? user.firstName : ''} ${user.lastName ? user.lastName : ''}`;
};

export const findUserRolesNameByIds = roles => {
  if (!roles) {
    return [];
  }
  const roleNames = userRoles.filter(el => roles.includes(el.id));

  return roleNames;
};
