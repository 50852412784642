import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Formsy from 'formsy-react';
import React, { useRef } from 'react';

const useStyles = makeStyles(() => ({
  loginEncompassButton: {
    boxShadow: 'none',
    height: 'auto',
    borderRadius: '33px',
    backgroundColor: '#006aa9',
    color: 'white !important',
    textDecoration: 'none !important',
    display: 'inline-block',
    outline: '#006aa9',
    border: 'solid',
    borderColor: '#006aa9',
    justifyContent: 'center',
    cursor: 'pointer',
    verticalAlign: 'middle',
    textAlign: 'center',
    transition: '0.4s',
    width: '2% auto',
    marginLeft: '2%',
    marginRight: '2%',
    justifySelf: 'center',
    justifyItems: 'center',
    marginTop: '2%',
    marginBottom: '2%',
    fontWeight: 'bold',
    fontFamily: 'Helvetica',
    fontSize: '1.7rem',
    lineHeight: '30px',
    '&:hover': {
      background: 'white',
      color: '#006aa9 !important'
    }
  }
}));

function EncompassLoginButton() {
  const classes = useStyles();

  const encompassClientID = process.env.REACT_APP_ENCOMPASS_CLIENT_ID;
  const redirectLink = encodeURIComponent(process.env.REACT_APP_REDIRECT_URL);

  const formRef = useRef(null);

  return (
    <div>
      <Formsy autoComplete="off" ref={formRef} className="flex flex-col justify-center">
        <Button
          type="root"
          href={`https://idp.elliemae.com/authorize?client_id=${encompassClientID}&response_type=code&redirect_uri=${redirectLink}&state=&scope=lp`}
          variant="contained"
          aria-label="Log In With Encompass"
          value="legacy"
          className={clsx(classes.loginEncompassButton, 'mx-auto mt-16 normal-case')}
        >
          Sign in with Encompass
        </Button>
      </Formsy>
    </div>
  );
}

export default EncompassLoginButton;
