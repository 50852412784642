import React from 'react';

const PagesConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: '/pages/errors/error-404',
      component: React.lazy(() => import('./errors/404/Error404Page'))
    },
    {
      path: '/pages/errors/error-500',
      component: React.lazy(() => import('./errors/500/Error500Page'))
    }
  ]
};

export default PagesConfig;
