import { PROCESSOR, LOCK_GUY, LOAN_OFFICER, LOAN_OFFICER_ASSISTANT, ACCOUNTING, ADMIN } from 'app/constants/userRoles';

/**
 * Authorization Roles
 */
const authRoles = {
  totalAccess: [ADMIN],
  closingEventsView: [PROCESSOR, LOAN_OFFICER, LOAN_OFFICER_ASSISTANT, LOCK_GUY, ACCOUNTING, ADMIN],
  closingEventsEditProjectedFees: [LOCK_GUY, ACCOUNTING, ADMIN],
  closingEventsEditCollectedFees: [ACCOUNTING, ADMIN],
  payroll: [ACCOUNTING, ADMIN],
  user: [PROCESSOR, LOAN_OFFICER, LOAN_OFFICER_ASSISTANT, LOCK_GUY, ACCOUNTING, ADMIN],
  onlyGuest: []
};

export default authRoles;
