import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import NavbarMobileToggleFab from 'app/fuse-layouts/shared-components/NavbarMobileToggleFab';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navbarCloseFolded, navbarOpenFolded, navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import { selectNavbarTheme } from 'app/store/fuse/settingsSlice';
import NavbarLayout1 from './NavbarLayout1';
import NavbarHome from './NavbarHome'; // Importar el navbar horizontal

const navbarWidth = 280;

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 4,
    [theme.breakpoints.up('lg')]: {
      width: navbarWidth,
      minWidth: navbarWidth
    }
  },
  wrapperFolded: {
    [theme.breakpoints.up('lg')]: {
      width: 64,
      minWidth: 64
    }
  },
  navbar: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    flex: '1 1 auto',
    width: navbarWidth,
    minWidth: navbarWidth,
    height: '100%',
    zIndex: 4,
    transition: theme.transitions.create(['width', 'min-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter
    }),
    boxShadow: theme.shadows[3]
  },
  left: {
    left: 0
  },
  right: {
    right: 0
  },
  folded: {
    position: 'absolute',
    width: 55,
    minWidth: 55,
    top: 0,
    bottom: 0
  },
  foldedAndOpened: {
    width: navbarWidth,
    minWidth: navbarWidth
  },
  navbarContent: {
    flex: '1 1 auto'
  },
  foldedAndClosed: {
    '& $navbarContent': {
      // Estilos para el navbar plegado
    }
  },
  // Estilos para el navbar horizontal
  navbarHomeWrapper: {
    width: '100%',  // Ocupa todo el ancho
    position: 'fixed',  // Fijo en la parte superior
    top: 0,
    zIndex: 5,  // Asegúrate de que esté por encima del resto del contenido
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.default
  }
}));

function NavbarWrapperLayout1(props) {
  const dispatch = useDispatch();
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const navbarTheme = useSelector(selectNavbarTheme);
  const navbar = useSelector(({ fuse }) => fuse.navbar);

  const classes = useStyles();

  const { folded } = config.navbar;
  const foldedAndClosed = folded && !navbar.foldedOpen;
  const foldedAndOpened = folded && navbar.foldedOpen;

  return (
    <>
      {/* Agrega el NavbarHome en la parte superior */}
      <div className={classes.navbarHomeWrapper}>
        <NavbarHome />
      </div>

      <ThemeProvider theme={navbarTheme}>
        <div id="fuse-navbar" className={clsx(classes.wrapper, folded && classes.wrapperFolded)}>
          {/* Navbar lateral (mantiene la lógica actual) */}
          <Hidden mdDown>
            <div
              className={clsx(
                classes.navbar,
                classes[config.navbar.position],
                folded && classes.folded,
                foldedAndOpened && classes.foldedAndOpened,
                foldedAndClosed && classes.foldedAndClosed
              )}
              onMouseEnter={() => foldedAndClosed && dispatch(navbarOpenFolded())}
              onMouseLeave={() => foldedAndOpened && dispatch(navbarCloseFolded())}
              style={{ backgroundColor: navbarTheme.palette.background.default }}
            >
              <NavbarLayout1 className={classes.navbarContent} />
            </div>
          </Hidden>

          {/* Navbar lateral para móviles */}
          <Hidden lgUp>
            <Drawer
              anchor={config.navbar.position}
              variant="temporary"
              open={navbar.mobileOpen}
              classes={{
                paper: classes.navbar
              }}
              onClose={() => dispatch(navbarCloseMobile())}
              ModalProps={{
                keepMounted: true // Mejor rendimiento en dispositivos móviles.
              }}
            >
              <NavbarLayout1 className={classes.navbarContent} />
            </Drawer>
          </Hidden>
        </div>
      </ThemeProvider>

      {/* Botón flotante para abrir el navbar lateral en móviles */}
      {config.navbar.display && !config.toolbar.display && (
        <Hidden lgUp>
          <NavbarMobileToggleFab />
        </Hidden>
      )}
    </>
  );
}

export default React.memo(NavbarWrapperLayout1);
