import React from 'react';

const HomeConfig = {
  settings: {
    layout: {}
  },
  routes: [
    // {
    //   path: '/dashboards/analytics',
    //   component: React.lazy(() => import('./analytics/Analytics'))
    // },
    {
      path: '/menu/home-page',
      component: React.lazy(() => import('./home-page/HomePage'))
    }
  ]
};

export default HomeConfig;
