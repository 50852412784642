import React from 'react';

const LegalConfig = {
  settings: {
    layout: {}
  },
  routes: [
    {
      path: '/legal/privacy-policy',
      component: React.lazy(() => import('./privacy-policy/PrivacyPolicy')),
    },
    {
      path: '/legal/terms',
      component: React.lazy(() => import('./terms/Terms')),
    },
    {
      path: '/legal/licensing',
      component: React.lazy(() => import('./licensing/Licensing')),
    },
  ]
};

export default LegalConfig;
