import React from 'react';

const DashboardsConfig = {
  settings: {
    layout: {}
  },
  routes: [
    {
      path: '/dashboards/analytics',
      component: React.lazy(() => import('./analytics/Analytics'))
    },
    {
      path: '/dashboards/monthly-chart',
      component: React.lazy(() => import('./monthly-chart/MonthlyChart'))
    }
  ]
};

export default DashboardsConfig;
