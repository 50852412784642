export const ADMIN = 'ADMIN';
export const PROCESSOR = 'PROCESSOR';
export const LOAN_OFFICER = 'LOAN_OFFICER';
export const LOAN_OFFICER_ASSISTANT = 'LOAN_OFFICER_ASSISTANT';
export const ACCOUNTING = 'ACCOUNTING';
export const LOCK_GUY = 'LOCK_GUY';
export const BACKUP_ASSISTANT = 'BACKUP_ASSISTANT';

export const ALL_LOAN_OFFICERS = 'ALL_LOAN_OFFICERS';

export const userRoles = [
  {
    id: ADMIN,
    name: 'Admin'
  },
  {
    id: PROCESSOR,
    name: 'Processor'
  },
  {
    id: LOAN_OFFICER,
    name: 'Loan Officer'
  },
  {
    id: LOAN_OFFICER_ASSISTANT,
    name: 'Loan Officer Assistant'
  },
  {
    id: ACCOUNTING,
    name: 'Accounting'
  },
  {
    id: LOCK_GUY,
    name: 'Lock Guy'
  }
];

export const employeeCompensationRoles = {
  [LOAN_OFFICER]: 'Loan Officer',
  [LOAN_OFFICER_ASSISTANT]: 'Assistant',
  [BACKUP_ASSISTANT]: 'Backup Assistant'
};
