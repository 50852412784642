import { createSlice } from '@reduxjs/toolkit';

import jwtService from 'app/services/jwtService';
import { setUserData } from './userSlice';

const initialState = {
  success: false,
  error: {
    username: null,
    password: null
  }
};

const loginSlice = createSlice({
  name: 'auth/login',
  initialState,
  reducers: {
    loginSuccess: state => {
      state.success = true;
    },
    loginError: (state, action) => {
      state.success = false;
      state.error = action.payload;
    }
  },
  extraReducers: {}
});

export const { loginSuccess, loginError } = loginSlice.actions;

export const submitLogin = ({ email, password }) => async dispatch => {
  return jwtService
    .signInWithEmailAndPassword(email, password)
    .then(user => {
      dispatch(setUserData(user));
      return dispatch(loginSuccess());
    })
    .catch(error => {
      return dispatch(loginError(error));
    });
};

export const submitLoginGoogle = ({ email, familyName, givenName, googleId }) => async dispatch => {
  return jwtService
    .signInWithGoogle(email, familyName, givenName, googleId)
    .then(user => {
      dispatch(setUserData(user));
      return dispatch(loginSuccess());
    })
    .catch(error => {
      return dispatch(loginError(error));
    });
};

export const submitEncompassToken = ({ authorizationCode, redirectUri }) => async dispatch => {
  return jwtService
    .signInWithEncompassToken(authorizationCode, redirectUri)
    .then(user => {
      dispatch(setUserData(user));
      return dispatch(loginSuccess());
    })
    .catch(error => {
      return dispatch(loginError(error));
    });
};

export default loginSlice.reducer;
