import { authRoles } from 'app/auth';
import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
  {
    id: 'applications',
    title: '',
    translate: '',
    type: 'group',
    icon: 'apps',
    children: [
      // Estos aparecen en el menu del dashboard
      {
        id: 'monthly-chart',
        title: 'Dashboard',
        translate: 'Dashboard',
        type: 'collapse',
        url: '/dashboards/monthly-chart',
        icon: 'insert_chart_outlined',
        children: [
          {
            id: 'minimum',
            icon: 'call_made',
            title: 'Sales Target',
            type: 'item',
            url: '/settings/sales-target',
            auth: authRoles.totalAccess,
            exact: true
          }
        ]
      },
      {
        id: 'closing-board',
        title: 'Closing Board',
        translate: 'Closing Board',
        icon: 'calendar_today',
        type: 'item',
        url: '/closing-board/calendar'
      },
      {
        id: 'analytics',
        title: 'Analytics',
        translate: 'Analytics',
        type: 'item',
        url: '/dashboards/analytics',
        icon: 'show_chart',
        auth: authRoles.totalAccess
      },
      {
        id: 'loan-search',
        title: 'Loan Search',
        translate: 'Loan Search',
        type: 'item',
        icon: 'search',
        url: '/loan/search'
      },
      {
        id: 'payroll',
        title: 'Payroll',
        translate: 'Payroll',
        type: 'collapse',
        icon: 'payments',
        auth: authRoles.payroll,
        children: [
          {
            id: 'outstandings',
            title: 'Outstandings',
            translate: 'Outstandings',
            type: 'item',
            url: '/payroll/outstandings',
            exact: true
          },
          {
            id: 'paystubs',
            title: 'Pay Stubs',
            translate: 'Pay Stubs',
            type: 'item',
            url: '/payroll/paystubs',
            exact: true
          }
        ]
      },
      {
        id: 'data-directory',
        title: 'Data Directory',
        translate: 'Data Directory',
        type: 'collapse',
        icon: 'account_box',
        auth: authRoles.totalAccess,
        children: [
          {
            id: 'lead-sources',
            icon: 'input',
            title: 'Lead Sources',
            type: 'item',
            url: '/data-directory/lead-sources',
            exact: true
          },
          {
            id: 'title-companies',
            icon: 'subtitles',
            title: 'Title Companies',
            type: 'item',
            url: '/data-directory/title-companies',
            exact: true
          },
          {
            id: 'lenders',
            icon: 'business',
            title: 'Lenders',
            type: 'item',
            url: '/data-directory/lenders',
            exact: true
          }
        ]
      },
      {
        id: 'settings',
        title: 'Settings',
        translate: 'Settings',
        type: 'collapse',
        icon: 'settings',
        auth: authRoles.totalAccess,
        children: [
          {
            id: 'users',
            icon: 'people',
            title: 'Users',
            type: 'item',
            url: '/settings/users',
            exact: true
          }
        ]
      },
      {
        type: 'divider',
        id: 'divider'
      },
      {
        id: 'logout',
        title: 'Logout',
        type: 'item',
        translate: 'Logout',
        icon: 'power_settings_new',
        url: '/logout',
        fg: '#CB1E2B'
      }
    ]
  }
];

export default navigationConfig;
