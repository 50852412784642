import moment from 'moment-timezone';

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.sssZ';

export const TIME_ZONE = 'America/Chicago';

export const MONTHS_PER_YEAR = 12;

export const stringToDate = strDate => {
  return moment(strDate, DATE_FORMAT).toDate();
};

export const dateToString = date => {
  return moment(date).format(DATE_FORMAT).toString();
};

/**
 * Converts a date to moment. If date is null, returns null.
 */
export const toMomentSafe = date => (date ? moment(date).tz(TIME_ZONE) : null);

export const formatMonthYear = date => (date ? moment(date).format('MMMM YYYY') : null);

export const formatDate = date => (date ? moment(date).format('MM/DD/YYYY') : null);

export const formatDateAndTime = date => (date ? moment(date).format('MM/DD/YYYY HH:mm') : null);

export const momentPlusMonths = (aMoment, months) => moment(aMoment).add(months, 'M');

export const toMomentStartOfDayAtTimeZone = date => (date ? toMomentSafe(date).startOf('day') : null);

export const startOfDayAtTimeZone = date => (date ? toMomentStartOfDayAtTimeZone(date).toDate() : null);

export const timeZoneToLocal = date => {
  if (!date) {
    return null;
  }
  const mo = moment.tz(date, TIME_ZONE);
  return new Date(mo.year(), mo.month(), mo.date(), mo.hour(), mo.minute(), 0);
};

export const localMomentToTimeZone = moment => {
  return moment.tz(
    {
      year: moment.year(),
      month: moment.month(),
      date: moment.date(),
      hour: moment.hour(),
      minute: moment.minute()
    },
    TIME_ZONE
  );
};

export const localMomentToTimeZoneStartOfTheDayDate = value => {
  return moment
    .tz(
      {
        year: value.year(),
        month: value.month(),
        date: value.date(),
        hour: 0,
        minute: 0
      },
      TIME_ZONE
    )
    .toDate();
};

export const nextDayAsIsoString = value => (value ? toMomentSafe(value).add(1, 'day').toISOString() : null);

export const minutesToNow = date => (date ? (new Date().getTime() - new Date(date).getTime()) / 60000 : null);
