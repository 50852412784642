import React from 'react';
import _ from '@lodash';
import { useDispatch, useSelector } from 'react-redux';
import Countdown, { zeroPad } from 'react-countdown';
import { useIdleTimer } from 'react-idle-timer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, DialogTitle, Typography } from '@material-ui/core';
import { openIdleTimeoutDialog, closeIdleTimeoutDialog } from 'app/store/idleTimeoutDialogSlice';
import { logoutUser } from 'app/auth/store/userSlice';
import instance from 'app/services/jwtService/jwtService';
import { useEventBetweenTabs } from 'app/main/utils/tabsUtils';

const IdleTimeoutDialog = () => {
  const dispatch = useDispatch();
  const idleTimeoutDialogState = useSelector(({ idleTimeoutDialog }) => idleTimeoutDialog);
  const { isLeader, reset } = useIdleTimer({
    timeout: parseInt(_.get(process.env, 'REACT_APP_IDLE_TIMEOUT_TIME_DIALOG', 60000)),
    onIdle: handleOnIdle,
    stopOnIdle: true,
    crossTab: {
      emitOnAllTabs: true
    }
  });
  const triggerReloadBetweenTabs = useEventBetweenTabs('IdleTimeoutDialog_RELOAD', () => {
    window.location.reload();
  });
  const triggerCloseDialogBetweenTabs = useEventBetweenTabs('IdleTimeoutDialog_CLOSE', () => {
    dispatch(closeIdleTimeoutDialog());
  });

  function onClose() {
    reset();
    dispatch(closeIdleTimeoutDialog());
    triggerCloseDialogBetweenTabs();
  }

  function onLogOutNow() {
    dispatch(logoutUser());
    dispatch(closeIdleTimeoutDialog());
    triggerReloadBetweenTabs();
  }

  function onStayLoggedIn() {
    reset();
    dispatch(closeIdleTimeoutDialog());
    triggerCloseDialogBetweenTabs();
  }

  function onComplete() {
    if (idleTimeoutDialogState.props.open) {
      dispatch(logoutUser('The session expired due to inactivity'));
      dispatch(closeIdleTimeoutDialog());
      if (isLeader()) {
        triggerReloadBetweenTabs();
      }
    }
  }

  function handleOnIdle(event) {
    if (!instance.getAccessToken()) {
      return;
    }
    if (idleTimeoutDialogState.props.open) {
      return;
    }
    dispatch(openIdleTimeoutDialog(parseInt(_.get(process.env, 'REACT_APP_IDLE_TIMEOUT_TIME_LOGOUT', 60000))));
  }

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" {...idleTimeoutDialogState.props}>
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Session Timeout
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>For your security, you will be logged out automatically in</Typography>
        <Typography align="center" variant="h5" gutterBottom>
          <Countdown
            date={Date.now() + idleTimeoutDialogState.time}
            onComplete={onComplete}
            zeroPadTime={2}
            renderer={({ minutes, seconds }) => (
              <div>
                {zeroPad(minutes)}:{zeroPad(seconds)}
              </div>
            )}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onLogOutNow} color="secondary">
          Log Out Now
        </Button>
        <Button autoFocus onClick={onStayLoggedIn} color="primary">
          Stay Logged In
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IdleTimeoutDialog;
