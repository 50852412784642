import FuseAnimate from '@fuse/core/FuseAnimate';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { submitEncompassToken } from 'app/auth/store/loginSlice';
import { useDispatch } from 'react-redux';

function EncompassLogin() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const authorizationCode = urlParams.get('code');

  const redirectUri = process.env.REACT_APP_REDIRECT_URL;

  const dispatch = useDispatch();
  const model = { authorizationCode, redirectUri };

  dispatch(submitEncompassToken(model));

  return (
    <div className="flex flex-col flex-1 items-center justify-center p-16">
      <div className="max-w-512 text-center">
        <FuseAnimate animation="transition.expandIn" delay={100}>
          <Typography variant="h1" color="inherit" className="font-medium mb-16">
            Success!
          </Typography>
        </FuseAnimate>

        <FuseAnimate delay={500}>
          <Typography variant="h5" color="textSecondary" className="mb-16">
            Encompass login. You will be redirected soon...
          </Typography>
        </FuseAnimate>
      </div>
    </div>
  );
}

export default EncompassLogin;
