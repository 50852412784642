import React from 'react';

const ClosingEventsConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: '/closing-board/calendar',
      component: React.lazy(() => import('./calendar/ClosingEventsCalendar'))
    },
    {
      path: '/closing-board/details/:closingEventId',
      component: React.lazy(() => import('./details/ClosingEventDetails'))
    }
  ]
};
export default ClosingEventsConfig;
