import React, { useRef, useEffect, useState } from 'react';
import FuseAnimate from '@fuse/core/FuseAnimate';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import JWTLoginTab from './tabs/JWTLoginTab';
import EncompassLoginButton from './tabs/EncompassLoginButton';
import GoogleLoginButton from './tabs/GoogleLoginButton';
import videoBackground from '../../../../src/background/videos/icentral-homepage-background-compressed.mp4';
import backgroundMusic from '../../../../src/background/music/loop.m4a';
import { VolumeUp, VolumeOff } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {},
  leftSection: {
    background: '#FFF',
    color: 'black',
    paddingTop: '16rem',
  },
  rightSection: {
    background: '#FFF',
    color: 'black',
    paddingTop: '7rem',
    position: 'relative',
    maxWidth: '48.3rem',
    paddingLeft: '77px',
    '@media screen and (max-width: 959px)': {
      paddingLeft: '7px',
      paddingRight: '7px',
      paddingTop: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  loginForm: {
    paddingBottom: '7.6rem !important',
    maxWidth: '34rem',
    paddingRight:'12px !important'
  },
  loginText: {
    fontSize: '3rem',
    fontFamily: 'Proxima Nova Medium, Helvetica',
    '@media screen and (max-width: 959px)': {
      fontSize: '1.5rem',
    },
  },
  loginLogo: {
    marginRight: '0',
    filter: 'brightness(0.85)',
    maxWidth: '397px',
    '@media screen and (max-width: 959px)': {
      maxWidth: '310px !important',
    },
  },
  loginTitle: {
    fontSize: '8.5rem',
    fontWeight: 600,
    fontFamily: 'Proxima Nova Medium, Helvetica',
    '@media screen and (max-width: 959px)': {
      fontSize: '2rem',
    },
  },
  loginModal: {
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    maxWidth: '103.8rem !important',
    '@media screen and (max-width: 959px)': {
      display: 'block !important',
    maxWidth: '36rem !important',
    },
  },
  line: {
    width: '220px',
    marginTop: 10,
    marginBottom: 10,
    borderBottomColor: '#CCCCCC',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    textAlign: 'center',
    lineHeight: '0.1em',
  },
  unmuteButton: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
    zIndex: 10,
  },
  videoOpacity: {
    opacity: 0.3
  },
  dividerLine: {
    borderColor: '#696969',
    marginTop: '11rem',
    marginBottom: '11rem',
  }
}));

function Login() {
  const classes = useStyles();
  const audioRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  const handleMuteToggle = () => {
    if (audioRef.current) {
      const newMutedState = !isMuted;
      audioRef.current.muted = newMutedState;
      setIsMuted(newMutedState);

      if (newMutedState) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = isMuted ? 0 : 1; // Controlar el volumen basado en isMuted
      audioRef.current.loop = true;
      if (!isMuted) {
        audioRef.current.play(); // Iniciar la reproducción si no está silenciado inicialmente
      }
    }
  }, [isMuted]);

  useEffect(() => {
    // Iniciar el audio en silencio al cargar la página
    if (audioRef.current) {
      audioRef.current.volume = 0;
    }
  }, []);

  return (
    <div className='overflow-hidden h-full'>
      <video className={clsx(classes.videoOpacity,'w-full h-full object-cover')} src={videoBackground} autoPlay loop muted />
      <audio ref={audioRef} src={backgroundMusic} loop />
      <div className={clsx(classes.root, 'flex flex-col flex-auto items-center justify-center flex-shrink-0 md:p-24 absolute w-full h-full top-0')}>
        <FuseAnimate animation='transition.expandIn'>
          <div className={clsx(classes.loginModal, 'flex w-full overflow-hidden bg-white')}>
            <div className={clsx(classes.leftSection, 'md:flex flex-1 items-center justify-center md:p-12')}>
              <div className='max-w-340 flex md:text-center items-center justify-center'>
                <FuseAnimate animation='transition.slideUpIn' delay={400}>
                  <img className={clsx(classes.loginLogo)} src='assets/images/logos/logo-icentral.png' alt='logo' />
                </FuseAnimate>
              </div>
            </div>
            <div className={clsx(classes.dividerLine,'hidden md:block border-l-1 h-[300px]')}></div>
            <Card className={clsx(classes.rightSection, 'flex flex-col w-full')} square elevation={0}>
              <CardContent className={clsx(classes.loginForm, 'flex flex-col items-center justify-center w-full')}>
                <JWTLoginTab />
                <div className={clsx(classes.line)}></div>
                {process.env.NODE_ENV !== 'production' && <GoogleLoginButton />}
                <button className={classes.unmuteButton} onClick={handleMuteToggle}>
                  {isMuted ? <VolumeOff className='text-26' /> : <VolumeUp className='text-26' />}
                </button>
                <EncompassLoginButton />
              </CardContent>
            </Card>
          </div>
        </FuseAnimate>
      </div>
    </div>
  );
}

export default Login;
