import { TextFieldFormsy } from '@fuse/core/formsy';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Formsy from 'formsy-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitLogin } from 'app/auth/store/loginSlice';

const useStyles = makeStyles(theme => ({
  loginButton: {
    color: '#FFF !important',
    backgroundColor: '#d51d2a !important',
    boxShadow: 'none',
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold'
  },
  googleButton: {
    borderRadius: '20px',
  },
  /* STYLES FOR TEXTFIELD */
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#FFFFFF',
      borderRadius: '0 !important',
      height: '46px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
    },
    '& .MuiInputBase-input': {
      height: '9px !important',
    },
    '& .Mui-focused': {
      backgroundColor: '#ECECEC',
      '&.MuiFormHelperText-root.Mui-error': {
        backgroundColor: '#161616'
      }
    },
  }, 
  inputField: {
    width: '292px',
    borderColor: '#b8b8b8 !important',
    '@media screen and (max-width: 959px)': {
      width: '308px',
    },
  }, 
  title: {
    fontSize: '16px'
  },
  btn: {
    fontSize: '12px !important'
  }
}));

function JWTLoginTab(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);

  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const formRef = useRef(null);

  useEffect(() => {
    if (login.error && (login.error.email || login.error.password)) {
      formRef.current.updateInputsWithError({
        ...login.error
      });
      disableButton();
    }
  }, [login.error]);

  function disableButton() {
    setIsFormValid(false);
  }

  function enableButton() {
    setIsFormValid(true);
  }

  function handleSubmit(model) {
    dispatch(submitLogin(model));
  }

  return (
    <div className="w-full">
      
      <h2 className={clsx(classes.title,"mb-4 font-semibold")}>Sign In</h2>
      
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        autoComplete="off"
        ref={formRef}
        className="flex flex-col justify-center w-full"
      >
        <TextFieldFormsy
          className={clsx(classes.root, classes.inputField, 'mb-16 border border-solid')}
          type="text"
          name="email"
          label="Email or Phone"
          value=""
          validations={{
            isEmail: true
          }}
          validationErrors={{
            isEmail: 'This is not a valid email'
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon className="text-20">email</Icon>
              </InputAdornment>
            )
          }}
          variant="filled"
          required
        />

        <TextFieldFormsy
          className={clsx(classes.root, classes.inputField, 'mb-14 border border-solid')}
          type="password"
          name="password"
          label="Password"
          value=""
          InputProps={{
            type: showPassword ? 'text' : 'password',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} className="m-0 p-1">
                  <Icon className="text-20 text-black">{showPassword ? 'visibility' : 'visibility_off'}</Icon>
                </IconButton>
              </InputAdornment>
            )
          }}
          variant="filled"
          required
          color="primary"
        />
        <div className="flex justify-start">
          <Button className={clsx(classes.btn, "text-blue-700 capitalize pl-0")}>
            Forgot email?
          </Button>
        </div>
        <Button
          type="submit"
          variant="contained"
          aria-label="LOG IN"
          disabled={!isFormValid}
          value="legacy"
          className={clsx(classes.loginButton, 'w-full mx-auto mt-16 normal-case')}
          style={{ display: 'none' }}
        >
          submit
        </Button>
      </Formsy>
    </div>
  );
}

export default JWTLoginTab;
