const apiVersion = 'v1';

const apiEndpoints = {
  LEAD_SOURCES: `${apiVersion}/lead-sources/`,
  TITLE_COMPANIES: `${apiVersion}/title-companies/`,
  LENDERS: `${apiVersion}/lenders/`,
  USERS: `${apiVersion}/users/`,
  GOOGLE: `${apiVersion}/auth/`,
  USER_DATA: `${apiVersion}/users/data/`,
  CLOSING_EVENTS: `${apiVersion}/closing-events/`,
  CLOSING_EVENTS_EMPLOYEE_COMPENSATION: `${apiVersion}/closing-events/:eventId/employee-compensation/:userId/:userRole`,
  CLOSING_EVENTS_SEARCH: `${apiVersion}/closing-events/search`,
  CLOSING_EVENTS_PAY: `${apiVersion}/closing-events/pay/`,
  CLOSING_EVENTS_PAYSTUBS: `${apiVersion}/closing-events/paystubs`,
  CLOSING_EVENTS_PAYSTUB_PDF: `${apiVersion}/closing-events/paystub-pdf`,
  CLOSING_EVENTS_OUTSTANDINGS: `${apiVersion}/closing-events/outstandings`,
  ANALYTICS: `${apiVersion}/analytics/`,
  SALES_TARGET: `${apiVersion}/sales-target/`,
  CLOSING_EVENTS_FOLDERS: `${apiVersion}/closing-events/folders/`,
  CLOSING_EVENTS_OFFICER: `${apiVersion}/closing-events/officer/`,
  ENCOMPASS_LOGIN: `${apiVersion}/encompass-login/`
};

export default apiEndpoints;
