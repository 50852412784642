import FuseNavigation from '@fuse/core/FuseNavigation';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectNavigation } from 'app/store/fuse/navigationSlice';

function Navigation(props) {
  const navigation = useSelector(selectNavigation);

  return (
    <FuseNavigation
      className={clsx('navigation', props.className)}
      navigation={navigation}
      layout={props.layout}
      dense={props.dense}
      active={props.active}
    >
      {' '}
    </FuseNavigation>
  );
}

Navigation.defaultProps = {
  layout: 'vertical'
};

export default React.memo(Navigation);
