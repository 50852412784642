import history from '@history';
import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const initAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
  // Add a response interceptor
  axios.interceptors.response.use(
    response => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    error => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response && error.response.status === 401) {
        // invalid credentials.
        history.push({
          pathname: '/logout'
        });
      }
      return Promise.reject(error);
    }
  );
};
