/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

import history from '@history';
import _ from '@lodash';
import { setDefaultSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';

import jwtService from 'app/services/jwtService';
import { formatUserFullName } from 'app/main/utils/usersUtils';

const initialState = {
  role: [], // guest
  data: {
    displayName: 'Nick Memeti',
    photoURL: '',
    email: 'nick@iloanrate.com'
  }
};

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    userLoggedOut: (state, action) => initialState
  },
  extraReducers: {}
});

export const { setUser, userLoggedOut } = userSlice.actions;

export const setUserData = userData => async (dispatch, getState) => {
  dispatch(setDefaultSettings({}));
  const role = userData.userRoles;
  dispatch(
    setUser({
      role,
      data: {
        ...userData,
        displayName: formatUserFullName(userData)
      }
    })
  );
};

// So far this is only used to update settings, but may be used for other data as avatar, password, etc.-
export const updateUserData = userData => async (dispatch, getState) => {
  const { user } = getState().auth;
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }

  jwtService
    .updateUserData(userData)
    .then(() => {
      // dispatch(showMessage({ message: 'User data saved with api' }));
    })
    .catch(error => {
      dispatch(showMessage({ message: error.message }));
    });
};

export const updateUserSettings = settings => async (dispatch, getState) => {
  const oldUserData = _.get(getState(), 'auth.user.data', { settings: {} });
  const userData = {
    ...oldUserData,
    settings: {
      ...oldUserData.settings,
      ...settings
    }
  };
  dispatch(updateUserData(userData));

  return dispatch(setUserData(userData));
};

export const updateUserShortcuts = shortcuts => async (dispatch, getState) => {
  const { user } = getState().auth;
  const newUser = {
    ...user,
    data: {
      ...user.data,
      shortcuts
    }
  };

  dispatch(updateUserData(user));

  return dispatch(setUserData(newUser));
};

export const logoutUser = (message = null) => async (dispatch, getState) => {
  history.push({
    pathname: '/login'
  });
  jwtService.logout(message);
  dispatch(userLoggedOut());
};

export default userSlice.reducer;
