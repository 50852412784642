import { createSlice } from '@reduxjs/toolkit';
import _ from '@lodash';
import { removeEnd } from 'app/main/utils/stringUtils';

const IGNORE_ACTIONS = ['closingEvents/details/getClosingEvent'];

const initialState = {
  spinningActions: [],
  showSpinner: false
};

function isAjaxPendingAction(action) {
  return action.type.endsWith('pending');
}
function isAjaxFulfilledAction(action) {
  return action.type.endsWith('fulfilled');
}
function isAjaxRejectedAction(action) {
  return action.type.endsWith('rejected');
}

function updateShowSpinning(state) {
  state.showSpinner = state.spinningActions.length > 0;
}

function isActionSpinnable(actionName) {
  return !IGNORE_ACTIONS.includes(actionName);
}

const spinnerSlice = createSlice({
  name: 'spinner',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(isAjaxPendingAction, (state, action) => {
        const actionName = removeEnd(action.type, '/pending');
        if (isActionSpinnable(actionName)) {
          state.spinningActions.push(actionName);
          updateShowSpinning(state);
        }
        return state;
      })
      .addMatcher(isAjaxFulfilledAction, (state, action) => {
        const actionName = removeEnd(action.type, '/fulfilled');
        _.remove(state.spinningActions, a => a === actionName);
        updateShowSpinning(state);
        return state;
      })
      .addMatcher(isAjaxRejectedAction, (state, action) => {
        const actionName = removeEnd(action.type, '/rejected');
        _.remove(state.spinningActions, a => a === actionName);
        updateShowSpinning(state);
        return state;
      });
  }
});

export const selectShowSpinner = state => _.get(state, 'spinner.spinner.showSpinner');

export default spinnerSlice.reducer;
