import React, { useRef, useEffect } from 'react';
import { gapi } from 'gapi-script';
import GoogleLogin from 'react-google-login';
import { useDispatch } from 'react-redux';
import { submitLoginGoogle } from 'app/auth/store/loginSlice';
import './GoogleLoginButton.css'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  loginButton: {
    width: '232px',
    borderRadius: '33px',
    border: 'solid',
  }
}));


const GoogleLoginButton = () => {
  const classes = useStyles();
  const formRef = useRef(null);
  const clientID = '279306422463-73sh0vgq691prrt3rr0mla8t2015b1q9.apps.googleusercontent.com';
  const dispatch = useDispatch();

  useEffect(() => {
    const start = () => {
      gapi.auth2.init({
        clientId: clientID
      });
    };
    gapi.load('client:auth2', start);
  }, []);

  const onSuccess = async response => {
    const profile = await response.profileObj 
    console.log('first', profile)
    sendUserDataToBackend(profile);
  };

  const onFailure = () => {
    console.log('Something went wrong');
  };

  const sendUserDataToBackend = (userData) => {
    dispatch(submitLoginGoogle(userData));
  };
  
  return (
    <div autoComplete="off" ref={formRef} className="flex flex-col justify-center items-center w-full text-[26px] mt-16">
        <GoogleLogin
          clientId={clientID}
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_policy'}
          className={clsx(classes.loginButton, 'loginButton')}
        />
    </div>
  );
};

export default GoogleLoginButton;
