import React from 'react';

const DataDirectoryConfig = {
  settings: {
    layout: {}
  },
  routes: [
    {
      path: '/data-directory/lead-sources',
      component: React.lazy(() => import('./lead-sources/LeadSources'))
    },
    {
      path: '/data-directory/lead-source/:leadSourceId',
      component: React.lazy(() => import('./lead-source/LeadSource'))
    },
    {
      path: '/data-directory/title-companies',
      component: React.lazy(() => import('./title-companies/TitleCompanies'))
    },
    {
      path: '/data-directory/title-company/:titleCompanyId',
      component: React.lazy(() => import('./title-company/TitleCompany'))
    },
    {
      path: '/data-directory/lenders',
      component: React.lazy(() => import('./lenders/Lenders'))
    },
    {
      path: '/data-directory/lender/:lenderId',
      component: React.lazy(() => import('./lender/Lender'))
    }
  ]
};

export default DataDirectoryConfig;
