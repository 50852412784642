import React from 'react';

const PayrollConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: '/payroll/outstandings',
      component: React.lazy(() => import('./outstandings/Outstandings'))
    },
    {
      path: '/payroll/paystubs',
      component: React.lazy(() => import('./paystubs/Paystubs'))
    }
  ]
};
export default PayrollConfig;
