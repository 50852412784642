import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    '&.user': {
      '& .username, & .email': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 140,
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut
        })
      }
    },
    background: 'linear-gradient(180deg, #FFFFFF 65%, #121212 55%)',
    maxWidth: 280
  },
  avatar: {
    width: 82,
    height: 82,
    fontSize: '2.5em',
    boxSizing: 'content-box',
    left: '10%',
    transform: 'translateX(-50%)',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    }),
    '&.initials': {
      width: 72,
      height: 72,
      padding: 5
    },
    '& > img': {
      borderRadius: '50%'
    }
  },
  userData: {
    paddingTop: '1.5rem'
  }
}));

function UserNavbarHeader(props) {
  const user = useSelector(({ auth }) => auth.user);

  const classes = useStyles();
  const userName = user.data.displayName;
  const { avatar } = user.data;

  const getInitials = userName =>
    userName
      .match(/(\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase();
  const getAvatarImg = avatar => {
    return <img className="max-w-none w-auto h-full" src={avatar} alt="avatar" />;
  };
  const avatarContent = avatar ? getAvatarImg(avatar) : getInitials(userName);

  return (
    <AppBar
      position="static"
      elevation={0}
      classes={{ root: classes.root }}
      className="user relative flex-row pt-20 px-1 mb-14 z-0 place-content-center"
    >
      <Avatar className={clsx(classes.avatar, 'avatar')} alt="">
        {avatarContent}
      </Avatar>
      <div className={clsx(classes.userData)}>
        <Typography className="username text-16 mb-16 whitespace-no-wrap text-black">
          {user.data.displayName}
        </Typography>
        <Typography className="email text-13 whitespace-no-wrap text-white">{user.data.email}</Typography>
      </div>
    </AppBar>
  );
}

export default UserNavbarHeader;
