import React from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Modal } from '@material-ui/core';
import withReducer from 'app/store/withReducer';
import reducer from './store';
import { selectShowSpinner } from './store/spinnerSlice';

function Spinner() {
  const showLoading = useSelector(selectShowSpinner);

  if (!showLoading) {
    return null;
  }

  return (
    <Modal open={showLoading} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
      <div className="flex flex-1 flex-col items-center justify-center p-12 h-full items-middle">
        <Typography className="text-20 mb-16 text-white">Loading...</Typography>
        <CircularProgress className="w-xs max-w-full" color="secondary" />
      </div>
    </Modal>
  );
}

export default withReducer('spinner', reducer)(Spinner);
