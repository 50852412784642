const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  props: {
    open: false
  },
  time: 0
};

const idleTimeoutDialogSlice = createSlice({
  name: 'idleTimeoutDialog',
  initialState,
  reducers: {
    openIdleTimeoutDialog: {
      reducer: (state, action) => {
        return {
          ...state,
          props: {
            open: true
          },
          ...action.payload
        };
      },
      prepare: time => {
        return {
          payload: { time }
        };
      }
    },
    closeIdleTimeoutDialog: state => {
      return {
        ...state,
        props: {
          open: false
        },
        time: 0
      };
    }
  }
});

export const { openIdleTimeoutDialog, closeIdleTimeoutDialog } = idleTimeoutDialogSlice.actions;

export default idleTimeoutDialogSlice.reducer;
