export function useEventBetweenTabs(name, listener = () => {}) {
  window.addEventListener('storage', event => {
    if (event.key === name) {
      listener(event.newValue);
    }
  });

  const triggerEventBetweenTabs = payload => {
    localStorage.setItem(name, JSON.stringify(payload));
    setTimeout(() => {
      localStorage.removeItem(name);
    }, 0);
  };

  return triggerEventBetweenTabs;
}
