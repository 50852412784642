import React from 'react';
import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import DataDirectoryConfig from 'app/main/data-directory/DataDirectoryConfig';
import SettingsConfig from 'app/main/settings/SettingsConfig';
import LoginConfig from 'app/main/login/LoginConfig';
import EncompassLoginConfig from 'app/main/encompass-login/EncompassLoginConfig';
import LogoutConfig from 'app/main/logout/LogoutConfig';
import ClosingEventsConfig from 'app/main/closing-board/ClosingEventsConfig';
import DashboardsConfig from 'app/main/dashboards/DashboardsConfig';
import LegalConfig from 'app/main/legal/LegalConfig';
import HomeConfig from 'app/main/menu/HomeConfig';
import PayrollConfig from 'app/main/payroll/PayrollConfig';
import LoanSearchConfig from 'app/main/loan-search/LoanSearchConfig';
import PagesConfig from 'app/main/pages/PagesConfig';
import authRoles from 'app/auth/authRoles';

// These configs should also be listed in iloanConfigs.js
const routeConfigs = [
  DataDirectoryConfig,
  SettingsConfig,
  LogoutConfig,
  LoginConfig,
  EncompassLoginConfig,
  ClosingEventsConfig,
  DashboardsConfig,
  LegalConfig,
  HomeConfig,
  PayrollConfig,
  LoanSearchConfig,
  PagesConfig
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, authRoles.user),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/menu/home-page" />
    // component: () => <Redirect to="dashboards/monthly-chart" />
  },
  {
    component: () => <Redirect to="/pages/errors/error-404" />
  }
];

export default routes;
